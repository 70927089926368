import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
// import { useParams } from 'react-router-dom';
import logo from "../images/logo.png";
import authHeader from "../services/auth-header";
import { globalAct } from "../store/global";

function AppHeader() {
  const { rootApiUrl, isLogedIn } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const [logoPath, setLogoPath] = useState(null);
  const location = useLocation();

  // useEffect(() => {
  //   async function getLogo() {
  //     await fetch("https://api.gis.khetibuddy.com/logo").then((res) => {
  //       if (res.ok) {
  //         res.json().then((data) => setLogoPath(data.logo.logo));
  //       }
  //     });
  //   }
  //   getLogo();
  // }, []);

  const logout = async() => {
    const res = await fetch(rootApiUrl + 'logout/', {
      method: "GET",
      headers: authHeader(),
    });

    if (res.ok) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("Is_Superuser");
      dispatch(globalAct.setIsLogedIn(false));
    }
  };

  return (
    <div className="flex items-center justify-between px-5 py-2 bg-white bg-opacity-40">
      <img
        src={logo}
        alt="Logo"
        className="object-contain rounded-md h-14"
      />
      {location.pathname === '/' && <ul className="flex items-center">
        <li className="text-white">
          <NavLink
            className={({ isActive }) => (isActive ? "hidden" : "")}
            to="/"
          >
            Dashboard
          </NavLink>
        </li>
        {isLogedIn && (
          <li className="ml-4 text-white cursor-pointer" onClick={logout}>
            Logout
          </li>
        )}
        {/* <li><NavLink to="/add-farm" className={({ isActive }) => isActive ? "hidden" : "rounded border p-2 text-white bg-ext-green border-ext-green ml-3"}>+ Add Farm</NavLink></li> */}
      </ul>}
    </div>
  );
}

export default AppHeader;

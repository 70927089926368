import React, { useEffect } from "react";
import Geocoder from "ol-geocoder";
import { useSelector } from "react-redux";
import "ol-geocoder/dist/ol-geocoder.min.css";

function SearchLocation(props) {
  const map = useSelector((state) => state.gis.map);
  const [center, setCenter] = React.useState([]);

  // set the map zoom and center to the center of the selected feature
  useEffect(() => {
    if(map && center){
      map.getView().setCenter(center)
      map.getView().setZoom(19);
    }
  }, [map, center])
  

  useEffect(() => {
    
    var geocoder = new Geocoder("nominatim", {
      provider: "bing",
      key: "Anv3VkCVN-WBUVDvhntN4QKnggFKU0UHYZ9TaFPxG0VN5d1W-nk8I3A6W72ZU7PD",
      lang: "en",
      placeholder: "Search for...",
      limit: 5,
      debug: false,
      autoComplete: true,
      keepOpen: false,
      targetType: "text-input",
      countrycodes: "IN",
    });
    geocoder.on("addresschosen", (evt) => {
      var feature = evt.feature;
      // debugger;
      geocoder.getLayer().getSource().clear();
      geocoder.getLayer().getSource().addFeature(feature);
      const center = feature.getGeometry().getCoordinates()
      setCenter(center);      
    });
    if (map) {
      if (map.getControls().getArray().length < 1) map.addControl(geocoder);
    }
  }, [map]);

  return null;
}

export default SearchLocation;

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faCloud, faImage, faSatellite } from '@fortawesome/free-solid-svg-icons'

function DataOnDate(props) {
  return (
    <ul className='p-3 rounded-lg border-r border-ext-darker text-left' >
        <li className='mb-2'><FontAwesomeIcon icon={faCalendarDays} className="text-lg mr-2 text-ext-green w-6"/> {props.date}</li>
        {/* <li className='mb-2'><FontAwesomeIcon icon={faCloud} className="text-lg mr-2 text-ext-green w-6"/> <span className='opacity-50'>{props.cloud}</span></li>
        <li className='mb-2'><FontAwesomeIcon icon={faImage} className="text-lg mr-2 text-ext-green w-6"/> <span className='opacity-50'>{props.image}</span></li> */}
        <li className='mb-2'><FontAwesomeIcon icon={faCloud} className="text-lg mr-2 text-ext-green w-6"/> <span className='opacity-50'>{props.sat}</span></li>
    </ul>
  )
}
// onClick={props.updateSelectedIndex(props.ind)}
export default DataOnDate
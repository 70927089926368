import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Vector as VectorSource } from 'ol/source';
import DragAndDrop from 'ol/interaction/DragAndDrop';
import { GPX, GeoJSON, IGC, KML, TopoJSON, WKT } from 'ol/format';
import OLVectorLayer from 'ol/layer/Vector';
import { getArea } from 'ol/sphere';
import { gisAction } from '../../../../store/gis';
import { toast } from 'react-toastify';

function DragAndDropOnMap() {
  const map = useSelector((state) => state.gis.map);
  const dispatch = useDispatch();
  const [dragAndDropInteraction, setDragAndDropInteraction] = useState();

  useEffect(() => {
    if (!map) return;
    setDragAndDropInteraction(
      new DragAndDrop({
        formatConstructors: [
          GPX,
          GeoJSON,
          IGC,
          new KML({ extractStyles: true }),
          TopoJSON,
        ],
      })
    );
  }, [map]);

  useEffect(() => {
    if (!dragAndDropInteraction) return;
    map.addInteraction(dragAndDropInteraction);
    dragAndDropInteraction.on('addfeatures', (event) => {
      const polygons = event.features.filter(
        (x) => x.getGeometry().getType() === 'Polygon'
      );
      if (polygons.length === 0) {
        toast.error('No polygon is delected');
      } else {
        const farm = polygons[0];
        const vectorSource = new VectorSource({
          features: [farm],
        });
        map.addLayer(
          new OLVectorLayer({
            source: vectorSource,
          })
        );
        map.getView().fit(vectorSource.getExtent());

        const geom = farm.getGeometry();
        const area = calculateArea(geom);
        dispatch(gisAction.updateArea(area));
        const geomWKt = wktConverter(geom);
        dispatch(gisAction.updateGeometryWKT(geomWKt));
      }
    });
  }, [dragAndDropInteraction]);

  const calculateArea = function (polygon) {
    const cloned = polygon.clone();
    cloned.transform('EPSG:4326', 'EPSG:3857');
    const area = getArea(cloned);
    return area;
  };

  const wktConverter = (polygon) => {
    const cloned = polygon.clone();
    let wktFormat = new WKT({
      splitcollection: true,
    });
    let geomWKt = wktFormat.writeGeometry(cloned);
    return geomWKt;
  };

  return null;
}

export default DragAndDropOnMap;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  MyLayers,
  TileLayer,
  VectorLayer,
  ImageLayer,
} from '../components/Mapping/components/Layers';
import MyMap from '../components/Mapping/components/Map/MyMap';
// import Popup from '../components/Mapping/components/Popup/Popup';
import { vector, xyz } from '../components/Mapping/components/Source';
import imgNorth from '../images/north.png';
import eviImg from '../images/evi.jpeg';
import ndviImg from '../images/ndvi.jpeg';
import ndmiImg from '../images/ndmi.jpeg';
import authHeader from '../services/auth-header';
import { get } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import { createEmpty, extend } from 'ol/extent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical,
  faRectangleXmark,
} from '@fortawesome/free-solid-svg-icons';

function FarmsMobile() {
  const { rootApiUrl, isLogedIn } = useSelector((state) => state.global);

  let params = new URL(document.location).searchParams;
  const [center, setCenter] = useState([78.9629, 20.5937]);
  const [imageURL, setImageURL] = useState('');
  const [farmDataOL, setFarmDataOL] = useState(null);
  const [measure, setMeasure] = useState('ndvi');
  const [showLegend, setShowLegend] = useState(false);
  const [extent, setExtent] = useState([]);
  const [legendURL, setLegendURL] = useState('');

  useEffect(() => {
    setMeasure(params.get('index'));
    setImageURL('');

    async function getFarmData() {
      const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        redirect: 'follow',
      };

      await fetch(
        rootApiUrl + 'farmdata_mobile/' + params.get('farm'),
        requestOptions
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          const fc_ol = new GeoJSON().readFeature(data.features[0], {
            featureProjection: get('EPSG:4326'),
          });
          setExtent(fc_ol.getGeometry().getExtent());
          setCenter([
            data.features[0].properties.lon,
            data.features[0].properties.lat,
          ]);
          setFarmDataOL(fc_ol);
        });
    }

    async function getImagery() {
      const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        redirect: 'follow',
      };

      await fetch(
        rootApiUrl +
          'imagery/' +
          params.get('farm') +
          '/' +
          params.get('index').toUpperCase() +
          '/' +
          params.get('date'),
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setImageURL(data.path);
          setLegendURL(data.legend);
        })
        .catch((err) => console.log(err));
    }

    getFarmData();
    getImagery();
  }, []);

  return (
    <div className='fixed inset-0'>
      <span className='absolute z-10 p-3 bg-white rounded-lg shadow-lg bottom-4 left-4'>
        {!showLegend && (
          <span
            className='text-xs font-bold cursor-pointer text-ext-darker'
            onClick={() => {
              setShowLegend(!showLegend);
            }}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} className='mr-2' /> Show
            Legend
          </span>
        )}
        {showLegend && (
          <FontAwesomeIcon
            icon={faRectangleXmark}
            className='absolute z-10 block text-2xl text-red-500 cursor-pointer -right-1 -top-1'
            onClick={() => {
              setShowLegend(!showLegend);
            }}
          />
        )}
        {showLegend && (
          <img
            src={
              legendURL
                ? rootApiUrl + legendURL
                : measure === 'ndvi'
                ? ndviImg
                : measure === 'ndmi'
                ? ndmiImg
                : measure === 'evi'
                ? eviImg
                : ''
            }
            alt='legend'
            className='h-[35vh]'
          />
        )}
        {/* {measure === 'ndmi' && showLegend && (
          <img
            src={legendURL ? legendURL : ndmiImg}
            alt='legend'
            className='h-[35vh]'
          />
        )} */}
        {/* {measure === 'ndmi' && showLegend && (
          <img
            src={legendURL ? legendURL : ndmiImg}
            alt='legend'
            className='h-[35vh]'
          />
        )} */}
        {/* {measure === 'evi' && showLegend && (
          <img
            src={legendURL ? legendURL : eviImg}
            alt='legend'
            className='h-[35vh]'
          />
        )} */}
      </span>
      <MyMap center={center} zoom={5} trackLocation={false}>
        <img
          src={imgNorth}
          alt='north'
          className='absolute z-10 object-contain p-1 rounded-md w-9 h-9 bg-ext-green-light bg-opacity-60 right-2 top-2'
        />
        {/* <Popup type='wms' /> */}
        <MyLayers>
          <TileLayer
            source={xyz('http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')}
            zIndex={0}
          />
          {imageURL && (
            <ImageLayer
              zIndex={1}
              urlPath={rootApiUrl + imageURL}
              extent={extent}
            />
          )}
          {farmDataOL && (
            <VectorLayer
              source={vector({
                features: [farmDataOL],
              })}
              zIndex={2}
            />
          )}
        </MyLayers>
      </MyMap>
    </div>
  );
}

export default FarmsMobile;

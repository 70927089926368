import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRectangleXmark,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AdviceModal(props) {
  const [fertigation, setFertigation] = useState();
  const [foliar, setFoliar] = useState();
  const [pest_disease_management, setPest_disease_management] = useState();
  const [drenching, setDrenching] = useState();
  const [other, setother] = useState();
  const [selectFertigation, setSelectFertigation] = useState(false);
  const [selectFoliar, setSelectFoliar] = useState(false);
  const [selectPest, setSelectPest] = useState(false);
  const [selectDrenching, setSelectDrenching] = useState(false);
  const [selectOther, setSelectOther] = useState(false);

  const { rootApiUrl } = useSelector((state) => state.global);
  useEffect(() => {
    setFertigation(props.advisory?.sel_advisory.Fertigation);
    setFoliar(props.advisory?.sel_advisory.Foliar);
    setPest_disease_management(
      props.advisory?.sel_advisory.pest_disease_management
    );
    setDrenching(props.advisory?.sel_advisory.Drenching);
  }, [props]);

  function inputHandler(evt) {
    if (evt.target.name === "fertigation") {
      setFertigation(evt.target.value);
    }
    if (evt.target.name === "foliar") {
      setFoliar(evt.target.value);
    }
    if (evt.target.name === "pest_disease_management") {
      setPest_disease_management(evt.target.value);
    }
    if (evt.target.name === "drenching") {
      setDrenching(evt.target.value);
    }
    if (evt.target.name === "other") {
      setother(evt.target.value);
    }
  }

  const pushNotification = () => {
    if (selectFertigation) {
      sendNotification("Fertigation", fertigation);
    }
    if (selectFoliar) {
      sendNotification("Foliar", foliar);
    }
    if (selectDrenching) {
      sendNotification("Drenching", drenching);
    }
    if (selectPest) {
      sendNotification("Pest & Disease Management", pest_disease_management);
    }
    if (selectOther) {
      sendNotification("Other", other);
    }
  };

  const sendNotification = (type, msg) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      cropId: props.cropId,
      queryType: type,
      message: msg,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(rootApiUrl + "farming/gis/details/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        result.error ? toast.error("Error: " + result.error) : toast.success("Notification send successfully");
        close()
      })
      .catch((error) => toast.error("Unable to send notification"));
  };

  const close = () => {
    setSelectFertigation(false);
    setSelectFoliar(false);
    setSelectPest(false);
    setSelectDrenching(false);
    setSelectOther(false);
    props.closeModal();
  };

   // Function : adding currently selected advices to the report
   function getSelected(){
    let tempSelected = {};
    if (selectFertigation)  tempSelected.fertigation = fertigation ? fertigation : "-";
    if (selectDrenching)  tempSelected.drenching = drenching ? drenching : "-";
    if (selectFoliar)  tempSelected.foliar = foliar ? foliar : "-";
    if (selectPest)  tempSelected.pest_disease_management = pest_disease_management ? pest_disease_management : "-";
    if (selectOther)  tempSelected.other = other ? other : "-";
    props.generatePDF(tempSelected);
  }

  return (
    <>
      <div
        className={
          props.show
            ? "fixed bg-black bg-opacity-50 inset-0 flex items-center justify-center z-50"
            : "hidden"
        }
      >
        <div className="container relative max-w-2xl p-5 m-3 text-sm text-white rounded-md shadow-md bg-ext-dark">
          <FontAwesomeIcon
            icon={faRectangleXmark}
            className="absolute z-10 block text-2xl cursor-pointer text-ext-green right-3 top-3"
            onClick={close}
          />

          <div className="mb-5">
            <div className="text-sm opacity-50">Our</div>
            <div className="mb-3 text-3xl font-extralight">
              <span className="uppercase">{props?.adviceFor}</span> Advisory
            </div>
          </div>
          <div className="grid items-center grid-cols-12 gap-4 mb-3">
            <div>
              <input
                type="checkbox"
                onChange={(x) => setSelectFertigation(x.target.checked)}
                checked = {selectFertigation}
              />
            </div>
            <div className="col-span-3 opacity-50">Fertigation</div>
            <div className="col-span-7">
              <textarea
                className="form-control"
                name="fertigation"
                value={fertigation}
                onChange={inputHandler}
              ></textarea>
            </div>
            <div className="text-right">
              <FontAwesomeIcon
                icon={faFloppyDisk}
                className="z-10 text-2xl cursor-pointer text-ext-green"
              />
            </div>
          </div>

          <div className="grid items-center grid-cols-12 gap-4 mb-3">
            <div>
              <input
                type="checkbox"
                checked={selectFoliar}
                onChange={(x) => setSelectFoliar(x.target.checked)}
              />
            </div>
            <div className="col-span-3 opacity-50">Foliar</div>
            <div className="col-span-7">
              <textarea
                className="form-control"
                name="foliar"
                value={foliar}
                onChange={inputHandler}
              ></textarea>
            </div>
            <div className="text-right">
              <FontAwesomeIcon
                icon={faFloppyDisk}
                className="z-10 text-2xl cursor-pointer text-ext-green"
              />
            </div>
          </div>

          <div className="grid items-center grid-cols-12 gap-4 mb-3">
            <div>
              <input
                type="checkbox"
                checked={selectDrenching}
                onChange={(x) => setSelectDrenching(x.target.checked)}
              />
            </div>
            <div className="col-span-3 opacity-50">Drenching </div>
            <div className="col-span-7">
              <textarea
                className="form-control"
                name="drenching"
                value={drenching}
                onChange={inputHandler}
              ></textarea>
            </div>
            <div className="text-right">
              <FontAwesomeIcon
                icon={faFloppyDisk}
                className="z-10 text-2xl cursor-pointer text-ext-green"
              />
            </div>
          </div>

          <div className="grid items-center grid-cols-12 gap-4 mb-3">
            <div>
              <input
                type="checkbox"
                checked={selectPest}
                onChange={(x) => setSelectPest(x.target.checked)}
              />
            </div>
            <div className="col-span-3 opacity-50">
              Pest & Disease Management
            </div>
            <div className="col-span-7">
              <textarea
                className="form-control"
                name="pest_disease_management"
                value={pest_disease_management}
                onChange={inputHandler}
              ></textarea>
            </div>
            <div className="text-right">
              <FontAwesomeIcon
                icon={faFloppyDisk}
                className="z-10 text-2xl cursor-pointer text-ext-green"
              />
            </div>
          </div>

          <div className="grid items-center grid-cols-12 gap-4 mb-3">
            <div>
              <input
                type="checkbox"
                checked={selectOther}
                onChange={(x) => setSelectOther(x.target.checked)}
              />
            </div>
            <div className="col-span-3 opacity-50">Other</div>
            <div className="col-span-7">
              <textarea
                className="form-control"
                name="other"
                value={other}
                onChange={inputHandler}
              ></textarea>
            </div>
            <div className="text-right">
              <FontAwesomeIcon
                icon={faFloppyDisk}
                className="z-10 text-2xl cursor-pointer text-ext-green"
              />
            </div>
          </div>

          <div className="grid items-center grid-cols-3 gap-4">
            <button className="w-full btn-primary" onClick={getSelected}>Submit to report</button>
            <button className="w-full btn-primary">
              Push to mobile schedule{" "}
            </button>
            <button className="w-full btn-primary" onClick={pushNotification}>
              Push to mobile notification
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdviceModal;

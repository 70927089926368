import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faEllipsisVertical,
  faRectangleXmark,
} from '@fortawesome/free-solid-svg-icons';
import AppLoader from '../ui/AppLoader';
import ComparisonMaps from '../Mapping/components/Map/ComparisonMaps';
import imgNorth from '../../images/north.png';
// import Popup from '../Mapping/components/Popup/Popup';
import {
  MyLayers,
  VectorLayer,
  TileLayer,
  ImageLayer,
} from '../Mapping/components/Layers';
import { vector, wms, xyz } from '../Mapping/components/Source';
import { useSelector } from 'react-redux';
import authHeader from '../../services/auth-header';
import { get } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import DataOnDate from '../farmPage/DataOnDate';
import eviImg from '../../images/evi.jpeg';
import ndviImg from '../../images/ndvi.jpeg';
import ndmiImg from '../../images/ndmi.jpeg';
import { toast } from 'react-toastify';

const Map1Component = ({ params }) => {
  const navigate = useNavigate();
  const { rootApiUrl, isLogedIn } = useSelector((state) => state.global);
  const [center, setCenter] = useState([78.9629, 20.5937]);
  const [isEditing, setIsEditing] = useState(false);
  const [farmDataOL, setFarmDataOL] = useState(null);
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [measure, setMeasure] = useState('ndvi');
  const [selectedValue, setSelectedValue] = useState(0);
  const [showLegend, setShowLegend] = useState(false);
  const [dropDownList, setDropDownList] = useState([]);
  const [imageURL, setImageURL] = useState('');
  const [meanValue, setMeanValue] = useState(0);
  const [statsValue, setStatsValue] = useState(true);

  useEffect(() => {
    if (!isLogedIn) {
      navigate('/login');
    }
  }, [isLogedIn]);

  useEffect(() => {
    if (!isLogedIn) return;
    async function getFarmData() {
      setLoading(true);

      const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        redirect: 'follow',
      };

      await fetch(rootApiUrl + 'onefarm/' + params.farmId + '/', requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            navigate('/');
          }
        })
        .then((data) => {
          const userId = localStorage.getItem('userId');
          const Is_Superuser = localStorage.getItem('Is_Superuser');
          if (
            data.properties.user === parseInt(userId) ||
            Is_Superuser === 'true'
          ) {
            setCenter([data.properties.lon, data.properties.lat]);
            if (!data.geometry) {
              navigate('/add-farm/' + data.id);
            } else {
              setFarmDataOL(
                new GeoJSON().readFeature(data, {
                  featureProjection: get('EPSG:4326'),
                })
              );
              getMesaureValues();
            }
          } else {
            navigate('/403');
          }
        });

      setLoading(false);
    }

    getFarmData();
  }, []);

  async function getMesaureValues() {
    setLoading(true);

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
      redirect: 'follow',
    };
    const userId = localStorage.getItem('userId');

    await fetch(rootApiUrl + 'indices/?user=' + userId, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          navigate('/');
        }
      })
      .then((data) => {
        if (data.length > 0) {
          setDropDownList(data);
          const mesaureValue = data[0].name.toLowerCase();
          setMeasure(mesaureValue);
          getDateValues(mesaureValue, data[0].stats !== '');
          setStatsValue(data[0].stats !== '');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occur please check logs');
      });

    setLoading(false);
  }

  function changeMeasureOpt(event) {
    setMeasure(event.target.value);
    getDateValues(event.target.value, true);
    setStatsValue(true);
  }

  function changeDates(value, index) {
    setSelectedValue(parseInt(index));
    getImagery(value.date, measure);
    meanValueMesaure(value.date, measure);
  }

  async function getDateValues(opt, statBoolen) {
    setLoading(true);

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    await fetch(rootApiUrl + 'dates/' + params.farmId, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.length > 0) {
          setValues(data);
          let excludeDates = [];
          data.forEach((x) => {
            if (x.value === null) excludeDates.push(Date.parse(x.date));
          });
          getImagery(data[0].date, opt);
          if (statBoolen) {
            meanValueMesaure(data[0].date, opt);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occur please check logs');
      });
    setLoading(false);
  }

  async function getImagery(dateValue, mesauresValue) {
    setLoading(true);
    setImageURL('');
    setMeanValue(0);

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    await fetch(
      rootApiUrl +
        'imagery/' +
        params.farmId +
        '/' +
        mesauresValue.toUpperCase() +
        '/' +
        dateValue,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setImageURL(data.path);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occur please check logs');
      });
    setLoading(false);
  }

  async function meanValueMesaure(dateValue, measure) {
    const indicesValue = dropDownList.filter((val) => {
      return val.name.toLowerCase() === measure.toLowerCase();
    });
    if (indicesValue[0]?.stats === '') {
      setStatsValue(indicesValue[0].stats !== '');
      return;
    }
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };
    const to_date_Value = Number(dateValue.split('-')[2]) + 1;
    const to_date =
      dateValue.split('-')[0] +
      '-' +
      dateValue.split('-')[1] +
      '-' +
      to_date_Value;
    await fetch(
      rootApiUrl +
        'statisticsUpdate/' +
        params.farmId +
        '/' +
        measure.toUpperCase() +
        '/' +
        dateValue +
        '/' +
        to_date +
        '/' +
        'P1D',
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setMeanValue(
          data[0].data[0].outputs.data.bands.B0.stats.mean.toFixed(3)
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occur please check logs');
      });
  }

  // let layerName =
  //   values.length > 0 &&
  //   'kb:' + measure + '-' + values[selectedValue]?.date + '-' + params.farmId;

  return (
    <>
      <AppLoader show={loading} />
      <div className='flex flex-col w-full lg:w-1/2 lg:border-r-2 lg:border-lime-700 bg-ext-dark text-ext-green-light'>
        <div className='relative h-[50vh] lg:h-auto lg:basis-3/4 text-ext-dark'>
          <span className='absolute z-10 p-2 bg-white rounded-lg shadow-lg bottom-4 left-4'>
            {!showLegend && (
              <span
                className='text-xs font-bold cursor-pointer text-ext-darker'
                onClick={() => {
                  setShowLegend(!showLegend);
                }}>
                <FontAwesomeIcon icon={faEllipsisVertical} className='mr-2' />{' '}
                Show Legend
              </span>
            )}
            {showLegend && (
              <FontAwesomeIcon
                icon={faRectangleXmark}
                className='absolute z-10 block text-2xl text-red-500 cursor-pointer -right-1 -top-1'
                onClick={() => {
                  setShowLegend(!showLegend);
                }}
              />
            )}
            {measure === 'ndvi' && showLegend && (
              <img src={ndviImg} alt='legend' className='h-[35vh]' />
            )}
            {measure === 'ndmi' && showLegend && (
              <img src={ndmiImg} alt='legend' className='h-[35vh]' />
            )}
            {measure === 'evi' && showLegend && (
              <img src={eviImg} alt='legend' className='h-[35vh]' />
            )}
          </span>
          <ComparisonMaps center={center} zoom={5} trackLocation={false}>
            <img
              src={imgNorth}
              alt='north'
              className='absolute z-10 object-contain p-1 rounded-md w-9 h-9 bg-ext-green-light bg-opacity-60 right-2 top-2'
            />
            {/* <Popup type='wms' edit={isEditing} isComparison={true} /> */}
            <MyLayers>
              <TileLayer
                source={xyz(
                  'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                )}
                isComparison={true}
                zIndex={0}
              />
              {/* {values.length > 0 && (
                <TileLayer
                  name='farmWms'
                  source={wms({
                    url: 'https://gis.khetibuddy.com/geoserver/kb/wms',
                    layer: layerName,
                  })}
                  isComparison={true}
                  zIndex={2}
                />
              )} */}
              {farmDataOL && (
                <VectorLayer
                  zIndex={2}
                  name='farmLayer'
                  isComparison={true}
                  source={vector({
                    features: [farmDataOL],
                  })}
                />
              )}
              {imageURL && (
                <ImageLayer zIndex={1} urlPath={imageURL} isComparison={true} />
              )}
            </MyLayers>
          </ComparisonMaps>
        </div>
        <div className='min-h-fit lg:h-auto lg:basis-1/4 p-2 bg-ext-dark'>
          <div className='flex justify-between '>
            <select
              className='text-2xl bg-transparent font-extralight cursor-pointer'
              value={measure}
              onChange={changeMeasureOpt}>
              {' '}
              {dropDownList.map((e, key) => {
                return (
                  <option
                    key={key}
                    value={e.name.toLowerCase()}
                    className='bg-black'>
                    {e.name.toUpperCase()}
                  </option>
                );
              })}
            </select>
            {(() => {
              if (statsValue) {
                return <div className='opacity-50'>{meanValue}</div>;
              }

              return null;
            })()}
          </div>
          <div className='flex items-center mt-3'>
            <div className='mr-3 text-center'>
              <FontAwesomeIcon icon={faCalendarDays} className='text-2xl' />
              <br></br>
              Date
            </div>
            <div className=' overflow-y-auto pb-1'>
              <div className='flex w-max'>
                {values?.map((value, index) =>
                  value.value ? (
                    <button
                      key={value.id}
                      className={
                        selectedValue === index
                          ? 'bg-ext-green bg-opacity-30 rounded-md'
                          : ''
                      }
                      type='submit'
                      onClick={() => {
                        changeDates(value, index);
                      }}>
                      <DataOnDate
                        date={value.date}
                        // cloud="100%"
                        // image="100%"
                        sat={value.value.toFixed(8)}
                      />
                    </button>
                  ) : (
                    <ul
                      key={index}
                      className='p-3 text-left border-r rounded-lg border-ext-darker'>
                      <li className='mb-2'>
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          className='w-6 mr-2 text-lg text-ext-green'
                        />{' '}
                        {value.date}
                      </li>
                      <li className='opacity-50'>Data not available</li>
                    </ul>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Map1Component;

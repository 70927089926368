import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import OLImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';

const ImageLayer = ({
  urlPath,
  extent = [],
  zIndex = 0,
  isMap2 = false,
  isComparison = false,
}) => {
  const map = useSelector((state) =>
    isComparison
      ? isMap2
        ? state.gis.icMap2
        : state.gis.icMap1
      : state.gis.map
  );

  useEffect(() => {
    if (!map) return;
    map.getLayers().forEach(function (el) {
      if (el.get('name') === 'farmLayer') {
        extent = el.getSource().getExtent();
      }
      if (el.get('name') === 'imageLayer') {
        map.removeLayer(el);
      }
    });

    let imageLayer = new OLImageLayer({
      source: new Static({
        url: urlPath,
        imageExtent: extent,
        projection: 'EPSG:4326',
        // ! TODO UNCOMMENT THIS LINE
        // crossOrigin: "anonymous",
      }),
    });
    imageLayer.set('name', 'imageLayer');
    map.addLayer(imageLayer);
    imageLayer.setZIndex(zIndex);

    return () => {
      if (map) {
        map.removeLayer(imageLayer);
      }
    };
  }, [map]);

  return null;
};

export default ImageLayer;

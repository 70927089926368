import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AppHeader from '../components/AppHeader';
import AppLoader from '../components/ui/AppLoader';
import { useSelector, useDispatch } from 'react-redux';
// import { useLocation } from "react-router-dom";
import MyMap from '../components/Mapping/components/Map/MyMap';
import { MyLayers, TileLayer } from '../components/Mapping/components/Layers';
import { osm, xyz } from '../components/Mapping/components/Source';
import DrawOnMap from '../components/Mapping/components/Draw/DrawOnMap';
import DragAndDropOnMap from '../components/Mapping/components/Draw/DragAndDropOnMap';
import SearchLocation from '../components/Mapping/components/SearchLocation/SearchLocation';
import FormatArea from '../components/Mapping/components/Draw/FormatArea';
import { gisAction } from '../store/gis';
import { fromLonLat } from 'ol/proj';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { transform } from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import imgNorth from '../images/north.png';
import authHeader from '../services/auth-header';
import { Link } from 'react-router-dom/dist';

function AddFarm() {
  proj4.defs('EPSG:32643', '+proj=utm +zone=43 +datum=WGS84 +units=m +no_defs');
  register(proj4);
  const params = useParams();
  const navigate = useNavigate();
  // const location = useLocation();
  const dispatch = useDispatch();
  const { rootApiUrl, isLogedIn } = useSelector((state) => state.global);
  const totalArea = useSelector((state) => state.gis.area);
  const map = useSelector((state) => state.gis.map);
  // const draw = useSelector((state) => state.gis.draw);
  const geom = useSelector((state) => state.gis.geometryWKT);

  const [farmName, setFarmName] = useState('');
  const [cropId, setCropId] = useState('');
  const [farmer, setFarmer] = useState('');
  const [sowingDate, setSowingDate] = useState('');
  const [crop, setCrop] = useState('');
  const startlnglt = [78.9629, 20.5937];
  const [center, setCenter] = useState([78.9629, 20.5937]);
  const [lat, setLat] = useState(21);
  const [lng, setLng] = useState(78);
  const [zoom, setZoom] = useState(5);
  const [alert, setAlert] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isLogedIn) {
      navigate('/login?back=' + window.location.pathname);
    }
  }, [isLogedIn]);

  useEffect(() => {
    if (!isLogedIn) return;
    async function getFarmData() {
      setLoading(true);

      const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        redirect: 'follow',
      };

      await fetch(rootApiUrl + 'onefarm/' + params.farmId + '/', requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.geometry !== null) {
            navigate('/farms/' + params.farmId);
          } else {
            setFarmName(data.properties.farmer);
            setCrop(data.properties.crop);
            setSowingDate(data.properties.sowingdate);
            setCropId(data.id);
            setLat(data.properties.lat ? data.properties.lat : 21);
            setLng(data.properties.lon ? data.properties.lon : 78);
            setFarmer(data.properties.farmer);
            if (data.properties.lat !== null && data.properties.lon !== null) {
              setCenter([data.properties.lon, data.properties.lat]);
              setZoom(15);
            }
          }
        });
      setLoading(false);
    }
    if (params.farmId) {
      getFarmData();
    }
  }, []);

  function cropIdHandler(event) {
    setCropId(event.target.value);
  }
  function sowingDateHandler(event) {
    setSowingDate(event.target.value);
  }
  function cropHandler(event) {
    setCrop(event.target.value);
  }

  function addNewFarm(event) {
    event.preventDefault();
    setLoading(true);
    if (totalArea <= 0) {
      setLoading(false);
      toast.error('Please draw a polygon');
      return;
    } else if (totalArea > 4046.85642 * 1000) {
      setLoading(false);
      toast.error('Maximum area should be 1000 Acre');
      return;
    }

    var raw = JSON.stringify({
      farmer: farmer,
      crop: crop,
      name: farmName,
      sowingdate: sowingDate,
      area: totalArea,
      geometry: geom,
      lon: lng,
      lat: lat,
    });
    var requestOptions = {
      method: 'PATCH',
      headers: authHeader(),
      body: raw,
      redirect: 'follow',
    };

    fetch(rootApiUrl + 'onefarm/' + cropId + '/', requestOptions)
      .then((response) => {
        if (response.status === 200) {
          setCropId('');
          setSowingDate('');
          setCrop('');
          dispatch(gisAction.updateArea(null));
          dispatch(gisAction.updateGeometryWKT(null));
          map.getLayers().forEach(function (el) {
            if (el.get('name') === 'drawLayer') {
              el.getSource().clear();
            }
          });
          map
            .getOverlays()
            .getArray()
            .map((x) => map.removeOverlay(x));
          setLoading(false);
          toast.success('Farm updated successfully');
          // fetch( rootApiUrl + "realValue/" + cropId )
          //   .then( ( res ) => res.json() )
          //   .then( ( data ) =>
          //   {
          //     if ( data.res.found )
          //     {
          //       toast.success( "Information Fetched for farm successfully" );
          //     } else
          //     {
          //       toast.warning( "No data found for added farm" );
          //     }
          //     setTimeout( () =>
          //     {
          //       navigate( "/farms/" + params.farmId );
          //     }, 6000 );
          //   } );
        } else {
          setLoading(false);
          toast.error('Farm not updated');
        }
      })
      .catch((error) => toast(error));
  }

  const locate = () => {
    let latlng = [lng, lat];
    setCenter(latlng);
    map.getView().setZoom(19);
  };

  const handleInputChange = (e) => {
    const target = e.target;
    if (target.name === 'lat') {
      setLat(target.value);
    }
    if (target.name === 'lng') {
      setLng(target.value);
    }
  };

  // setAreaFormat(totalArea)
  return (
    <div className='flex flex-col-reverse h-screen lg:flex-row'>
      <div className='relative h-screen basis-7/12 lg:basis-4/12 bg-ext-dark text-ext-green-light'>
        <Link to={'/'}>
          <AppHeader />
        </Link>
        <AppLoader show={loading} />
        <ToastContainer position='top-center' closeOnClick pauseOnHover autoClose={2500} />
        <div className='absolute inset-x-0 bottom-0 px-5 py-6 overflow-auto top-20'>
          <small>
            Draw farm or drag and drop GPX, GeoJSON, IGC, KML, or TopoJSON files
            on to the map. There is no projection transform support, so drag and
            drop feature will only work with data in EPSG:4326 and EPSG:3857.
          </small>
          <h3 className='pb-2 mb-2 text-xl font-extralight'>Locate</h3>{' '}
          <div className='grid items-end grid-cols-3 gap-3 pb-5 mb-5 border-b border-ext-darker'>
            <div>
              Longitude:
              <input
                type='text'
                className='w-full p-2 mt-2 border border-black rounded bg-ext-darker'
                name='lng'
                placeholder='Long'
                value={lng}
                onChange={handleInputChange}
              />
            </div>
            <div>
              Latitude:
              <input
                type='text'
                className='w-full p-2 mt-2 border border-black rounded bg-ext-darker'
                placeholder='Lat'
                name='lat'
                value={lat}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <button
                className='w-full p-2 text-white border rounded'
                onClick={locate}
              >
                Locate
              </button>
            </div>
          </div>
          <form onSubmit={addNewFarm}>
            <label className='block my-2'>Crop ID: *</label>
            <input
              type='text'
              className='w-full p-2 mb-5 border border-black rounded bg-ext-darker'
              placeholder='Farm Name'
              value={cropId}
              onChange={cropIdHandler}
              required
            />

            <label className='block my-2'>Date of sowing: *</label>
            <input
              type='date'
              className='w-full p-2 mb-5 border border-black rounded bg-ext-darker'
              placeholder='Date of sowing'
              value={sowingDate}
              onChange={sowingDateHandler}
              required
            />

            <label className='block my-2'>Crop Name: *</label>
            <input
              type='text'
              className='w-full p-2 mb-5 border border-black rounded bg-ext-darker'
              placeholder='Date of sowing'
              value={crop}
              onChange={cropHandler}
              required
            />

            <div className='flex items-center justify-between mb-5'>
              <label htmlFor=''>Area: </label>
              <span className='text-4xl font-light'>
                <FormatArea area={totalArea} />
              </span>
            </div>
            <div className='pb-2 text-yellow-200'>{alert}</div>
            <button className='w-full p-2 mb-5 text-white border rounded bg-ext-green border-ext-green'>
              Add Farm
            </button>
          </form>
          <ul className='mb-5'>
            <li>Maximum area is 1000 Acre</li>
            {/* <li>No self-intersections</li> */}
          </ul>
          <ol className='ml-4 text-sm list-decimal opacity-50'>
            {/* <li>Click on the polygon tool to activate draw mode.</li> */}
            <li>
              Place the pointer on the map and click the location of the first
              point to start drawing.
            </li>
            <li>
              Continue clicking at each corner of the shape until you have
              created the polygon.
            </li>
            <li>Click the first point to stop drawing.</li>
            <li>Move any point to edit the polygon.</li>
          </ol>
        </div>
      </div>
      <div className='relative basis-5/12 lg:basis-8/12'>
        <MyMap center={center} zoom={zoom} trackLocation={true}>
          <img
            src={imgNorth}
            alt='north'
            className='absolute z-10 object-contain p-1 rounded-md w-9 h-9 bg-ext-green-light bg-opacity-60 right-2 top-2'
          />
          <SearchLocation />
          <MyLayers>
            <TileLayer
              source={xyz('http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')}
              zIndex={0}
            />
            <DrawOnMap type='Polygon' />
            <DragAndDropOnMap />
          </MyLayers>
        </MyMap>
      </div>
    </div>
  );
}

export default AddFarm;
